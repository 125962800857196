<template>
  <div>
    Fighters

    <div>
      <v-text-field
        placeholder="Search fighter"
        outlined
        v-model="searchString"
      />

      <v-select
        v-model="searchField"
        :items="searchableFields"
        item-text="text"
        item-value="field"
        label="Select"
        return-object
        single-line
        required
      ></v-select>

      <v-btn v-on:click="search">Serach</v-btn>
    </div>
    <div :key="index" v-for="(fighter, index) in fighters">
      <FighterCard :fighter="fighter.data()" />
    </div>
  </div>
</template>
<script>
// import { getFighters } from '../services/database'
import { getFighters, searchFighters } from "../../services/database";

import FighterCard from "../../components/authenticated/FighterCard";

export default {
  name: "Fighters",
  components: {
    FighterCard,
  },
  data: () => ({
    fighters: [],
    searchString: null,
    searchField: null,
    searchableFields: [
      {
        text: 'First Name',
        field: 'first_name'
      },
      {
        text: 'Last Name',
        field: 'last_name'
      }
    ]
  }),
  methods: {
    search() {
      if (this.searchString.length) {
        searchFighters(this.searchString, this.searchField.field).then((searchedFightersDocs) => {
          this.fighters = searchedFightersDocs.docs;
        });
      } else {
        this.loadAllFighters();
      }
    },
    loadAllFighters() {
      getFighters().then((fighterDocs) => {
        this.fighters = fighterDocs.docs;
      });
    },
  },
  mounted() {
    this.loadAllFighters();
  },
};
</script>