<template>
  <div id="analytics-container">
    <v-progress-circular
      id="events-loader"
      v-if="loading"
      :size="70"
      color="black"
      indeterminate
    ></v-progress-circular>

    <div v-if="!loading && analytics">
      <div class="d-flex title-area text-left">
        <BackButton
          v-if="breakpoint.smAndUp"
          @click="$router.go(-1)"
          class="ml-2 mr-6"
        />

        <div class="event-title">
          <div class="event-title-number">
            {{ ufcEventNumber }}: {{ ufcFighterTitle }}
          </div>
          <div>
            {{ analytics.red.name.family }} vs
            {{ analytics.blue.name.family }}
          </div>
        </div>
      </div>
      <div class="analytics-content">
        <v-select
          :label="selectedCategoryLabel"
          v-model="selectedCategory"
          :items="categories"
          return-object
          outlined
          dense
          :class="{ 'd-inline-block': breakpoint.smAndUp }"
        ></v-select>

        <div class="chart-container">
          <canvas ref="chart" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/authenticated/BackButton";
import { getAnalytics } from "../../services/database";

import {
  Chart,
  LineController,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Legend,
} from "chart.js";
import { capitalise } from "../../services/utils";
import { logEvent } from "../../services/analytics";

Chart.register(
  LineController,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Legend
);

const LINE_TENSION = 0.2;

export default {
  name: "Analytics",
  components: {
    BackButton,
  },
  data() {
    return {
      chart: null,
      breakpoint: {},
      loading: false,
      analytics: null,
      selectedCategory: "Significant Strikes",
      selectedCategoryLabel: "Avereage round-by-round",
      categories: [
        {
          label: "Avereage round-by-round",
          text: "Significant Strikes",
          function: this.getChartData,
          dataKey: "significantStrikes",
          chartType: "line",
        },
        {
          label: "Avereage round-by-round",
          text: "Total Strikes",
          function: this.getChartData,
          dataKey: "totalStrikes",
          chartType: "line",
        },
        {
          label: "Avereage round-by-round",
          text: "Takedowns",
          function: this.getChartData,
          dataKey: "takedowns",
          chartType: "line",
        },
        {
          label: "Avereage round-by-round",
          text: "Submission Attempts",
          function: this.getChartData,
          dataKey: "submissionAttempts",
          chartType: "line",
        },
        {
          label: "Avereage round-by-round",
          text: "Reversals",
          function: this.getChartData,
          dataKey: "reversals",
          chartType: "line",
        },
        {
          label: "Avereage round-by-round",
          text: "Knockdowns",
          function: this.getChartData,
          dataKey: "knockdowns",
          chartType: "line",
        },
        // {
        //   label: "Round-by-round",
        //   text: "Wins by Round",
        //   function: this.getWinsByRound,
        //   chartType: "bar",
        //   dataKey: "all",
        // },
        // {
        //   label: "Round-by-round",
        //   text: "Knockout Wins by Round",
        //   function: this.getWinsByRound,
        //   chartType: "bar",
        //   dataKey: "KO/TKO",
        // },
        // {
        //   label: "Round-by-round",
        //   text: "Submission Wins by Round",
        //   function: this.getWinsByRound,
        //   chartType: "bar",
        //   dataKey: "Submission",
        // },
      ],
    };
  },
  watch: {
    selectedCategory(newValue) {
      this.selectedCategoryLabel = newValue.label;

      const datasets = [
        {
          data: newValue.function(newValue.dataKey, "red"),
          borderColor: "red",
          backgroundColor: "red",
          label: this.analytics.red.name.family,
          lineTension: LINE_TENSION,
        },
        {
          data: newValue.function(newValue.dataKey, "blue"),
          borderColor: "blue",
          backgroundColor: "blue",
          label: this.analytics.blue.name.family,
          lineTension: LINE_TENSION,
        },
      ];

      this.renderChart(datasets, newValue.chartType);
    },
  },
  computed: {
    ufcEventNumber() {
      if (this.analytics && this.analytics.event && this.analytics.event.name) {
        const ppvMatch = this.analytics.event.name.match("UFC [0-9]{3}");
        const fightNightMatch =
          this.analytics.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match ? match[0] : "";
      }

      return "";
    },
    ufcFighterTitle() {
      if (this.analytics && this.analytics.event && this.analytics.event.name) {
        const ppvMatch = this.analytics.event.name.match("UFC [0-9]{3}");
        const fightNightMatch =
          this.analytics.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match
          ? this.analytics.event.name.replace(match[0], "")
          : this.analytics.event.name;
      }

      return "";
    },
  },
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;

    this.loading = true;
    getAnalytics(this.$route.params.eventId, this.$route.params.fightId)
      .then((analytics) => {
        this.analytics = analytics;
      })
      .finally(() => {
        this.loading = false;

        logEvent("analytics");

        this.$nextTick(() => {
          this.renderChart([
            {
              data: this.getChartData("significantStrikes", "red"),
              borderColor: "red",
              backgroundColor: "red",
              label: this.analytics.red.name.family,
              lineTension: LINE_TENSION,
            },
            {
              data: this.getChartData("significantStrikes", "blue"),
              borderColor: "blue",
              backgroundColor: "blue",
              label: this.analytics.blue.name.family,
              lineTension: LINE_TENSION,
            },
          ]);
        });
      });
  },
  methods: {
    getChartData(dataKey, colour) {
      let count = 0;
      let data = [
        {
          x: "Round 1",
          y: null,
        },
        {
          x: "Round 2",
          y: null,
        },
        {
          x: "Round 3",
          y: null,
        },
        {
          x: "Round 4",
          y: null,
        },
        {
          x: "Round 5",
          y: null,
        },
      ];

      // add data to round object
      // then once looped through divide to get an average
      this.analytics[colour].history.forEach((historicalFight) => {
        if (
          historicalFight.fighters.red.athleteURL &&
          historicalFight.fighters.blue.athleteURL
        ) {
          const fighterColour =
            historicalFight.fighters.red.athleteURL ==
            this.analytics[colour].athleteURL
              ? "red"
              : "blue";

          if (
            historicalFight.fightOverview &&
            historicalFight.fightOverview.roundStats
          ) {
            const roundStats = historicalFight.fightOverview.roundStats;

            Object.keys(roundStats).forEach((roundKey) => {
              count++;
              const capitalisedKey = capitalise(roundKey);
              const roundNumber = capitalisedKey.slice(-1);
              const dataLabel = capitalisedKey.replace(
                roundNumber,
                " " + roundNumber
              );

              const Landed =
                roundStats[roundKey][dataKey][fighterColour].landed;

              const chartData = data.find(
                (chartData) => chartData.x == dataLabel
              );

              if (Landed) {
                if (chartData.y == null) chartData.y = 0;
                chartData.y += Landed;
              }
            });
          }
        }
      });

      data = data.map((roundData) => {
        return {
          x: roundData.x,
          y: roundData.y ? roundData.y / count : null,
        };
      });

      return data;
    },
    getWinsByRound(dataKey, colour) {
      const data = [
        {
          x: "Round 1",
          y: 0,
        },
        {
          x: "Round 2",
          y: 0,
        },
        {
          x: "Round 3",
          y: 0,
        },
        {
          x: "Round 4",
          y: 0,
        },
        {
          x: "Round 5",
          y: 0,
        },
      ];

      this.analytics[colour].history.forEach((historicalFight) => {
        const fighterColour =
          historicalFight.fighters.red.athleteURL ==
          this.analytics[colour].athleteURL
            ? "red"
            : "blue";

        console.log(
          "------ historicalFight.methodOfVictory",
          historicalFight.methodOfVictory
        );

        if (dataKey == "all") {
          if (fighterColour == historicalFight.winner) {
            data[historicalFight.rounds - 1].y++;
          }
        } else {
          if (
            fighterColour == historicalFight.winner &&
            historicalFight.methodOfVictory == dataKey
          ) {
            data[historicalFight.rounds - 1].y++;
          }
        }
      });

      return data;
    },
    renderChart(datasets, chartType = "line") {
      if (this.chart) this.chart.destroy();
      this.chart = new Chart(this.$refs.chart, {
        type: chartType,
        data: { datasets },
        options: {
          scales: {
            yAxis: {
              min: 0,
              ticks: {
                stepSize: 1,
              }
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "top",
              labels: {
                boxWidth: chartType == "line" ? 40 : 42,
                boxHeight: chartType == "line" ? 3 : 6,
                font: {
                  size: 16,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
<style>
#analytics-container .v-input input {
  cursor: pointer !important;
}

.v-list-item__title {
  font-weight: normal !important;
  font-size: 1rem !important;
}

.analytics-content {
  padding: 14px;
}
</style>
