var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container--outer"},[_c('div',{staticClass:"container--inner"},[_c('div',{staticClass:"horizontal-line",style:({ width: _vm.horizontalWidth + 'px' })}),_c('div',{staticClass:"vertical-line",style:({
        height: _vm.height + 'px',
        marginLeft: _vm.verticalMarginLeft + 'px',
      })}),_c('div',{staticClass:"horizontal-line",style:({ width: _vm.horizontalWidth + 'px' })}),_c('div',{ref:"textElement",staticClass:"text",style:({
        paddingTop: _vm.textPadding + 'px',
        paddingBottom: _vm.textPadding + 'px',
        fontSize: _vm.textFontSize + 'px',
        top: _vm.textTop + 'px',
        left: _vm.textLeft + 'px',
      })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }