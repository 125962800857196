<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
@font-face {
  font-family: Sternbach;
  src: url("./fonts/Sternbach.otf");
  font-weight: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Russo+One&display=swap");

.ufc-font {
  font-family: Sternbach, sans-serif;
  font-style: italic;
  transform: scale(1, 0.9);
}

.predictor-font {
  font-family: "Russo One", sans-serif;
  font-style: italic;
}

html,
body {
  background-color: black;
}

v-app {
  background-color: black;
}
</style>
