var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"past-fight"},[(_vm.left && this.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"fighter-image__container",style:(`background-image: url(${
      _vm.fight.fighterColour == 'red'
        ? _vm.fight.blueFighter.image
        : _vm.fight.redFighter.image
    })`)}):_vm._e(),_c('div',{staticClass:"details"},[(this.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"win-loss-indicator",class:_vm.fight.outcome.replace(/ /g, '-')},[_vm._v(" "+_vm._s(_vm.fight.outcome.toUpperCase())+" ")]):_vm._e(),(this.$vuetify.breakpoint.xs)?_c('div',{staticClass:"fighter-image__container",style:(`background-image: url(${
        _vm.fight.fighterColour == 'red'
          ? _vm.fight.blueFighter.image
          : _vm.fight.redFighter.image
      }); width: 100%; height: 100px;`)}):_vm._e(),(this.$vuetify.breakpoint.xs)?_c('div',{staticClass:"win-loss-indicator",class:{ win: _vm.fight.didWin, loss: !_vm.fight.didWin }},[_vm._v(" "+_vm._s(_vm.fight.didWin ? "WIN" : "LOSS")+" ")]):_vm._e(),_c('div',{staticClass:"fight-against__text"},[_vm._v(" "+_vm._s(_vm.fight.fighterColour == "red" ? _vm.fight.blueFighter.familyName : _vm.fight.redFighter.familyName)+" ")]),_c('div',{staticClass:"fight-date"},[_vm._v(_vm._s(_vm.fightDate))])]),(!_vm.left && this.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"fighter-image__container",style:(`background-image: url(${
      _vm.fight.fighterColour == 'red'
        ? _vm.fight.blueFighter.image
        : _vm.fight.redFighter.image
    })`)}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }