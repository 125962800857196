var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fight-card",on:{"click":() => _vm.navigate()}},[_c('div',{staticClass:"fight-overview"},[_c('div',{staticClass:"red fighter-container",class:{ winner: _vm.fight.winner === 'red' }},[_c('div',{staticClass:"fighter-image",class:{
          'large-screen': _vm.breakpoint.smAndUp,
          'loser-image': _vm.fight.winner === 'blue',
        },style:({
          'background-image': `url(${
            _vm.redAthleteImage ? _vm.redAthleteImage : _vm.missingImage
          })`,
        })}),(_vm.fight.winner === 'red')?_c('div',{staticClass:"win-indicator"},[_vm._v("Winner")]):_vm._e()]),_c('div',{staticClass:"card__center-text",class:{ 'card__center-text--large': _vm.breakpoint.mdAndUp }},[_c('div',{staticClass:"fight-card__additional-info"},[_vm._v(" "+_vm._s(_vm.fight.weightClass .replace("UFC", "") .replace("Bout", "") .replace("Women's", "") .trim())+" ")]),_c('div',{staticClass:"bout__text",class:{ 'bout__text--large': _vm.breakpoint.mdAndUp }},[_c('div',{staticClass:"fighter-name red-fighter",class:{ 'fighter-name--small-font': _vm.breakpoint.xs }},[_c('div',{staticClass:"fighter-name-split__container",class:{ 'prediction red': _vm.isRedPredictedToWin },style:({
              padding: _vm.breakpoint.mdAndUp ? '8px 16px' : '4px',
              'text-align': _vm.breakpoint.mdAndUp ? 'unset' : 'center',
            })},[(_vm.breakpoint.mdAndUp)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.red.name.given)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.red.name.family)+" ")])]):_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.red.name.given)+" "+_vm._s(_vm.fight.fighters.red.name.family)+" ")]),_vm._t("post-red-name"),(_vm.isRedPredictedToWin)?_c('div',{staticClass:"prediction-text"},[_vm._v(" predicted win ")]):_vm._e()],2)]),_c('div',{staticClass:"vs-text"},[_vm._v("vs")]),_c('div',{staticClass:"fighter-name blue-fighter",class:{ 'fighter-name--small-font': _vm.breakpoint.xs }},[_c('div',{staticClass:"fighter-name-split__container",class:{ 'prediction blue': _vm.isBluePredictedToWin },style:({
              padding: _vm.breakpoint.mdAndUp ? '8px 16px' : '4px',
              'text-align': _vm.breakpoint.mdAndUp ? 'unset' : 'center',
            })},[(_vm.breakpoint.mdAndUp)?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.blue.name.given)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.blue.name.family)+" ")])]):_c('div',[_vm._v(" "+_vm._s(_vm.fight.fighters.blue.name.given)+" "+_vm._s(_vm.fight.fighters.blue.name.family)+" ")]),_vm._t("post-blue-name"),(_vm.isBluePredictedToWin)?_c('div',{staticClass:"prediction-text"},[_vm._v(" predicted win ")]):_vm._e()],2)])]),(_vm.isNotEnoughDataForPrediction)?_c('div',{staticClass:"not-enough-data"},[_vm._v(" Not enough fights in the UFC for a prediction ")]):_vm._e(),(_vm.showDetails && _vm.fight.methodOfVictory)?_c('div',{staticClass:"fight-card__additional-info fight-card__additional-info--bottom"},[_c('div',[_vm._v(_vm._s(_vm.fight.methodOfVictory))])]):_vm._e()]),_c('div',{staticClass:"blue fighter-container",class:{ winner: _vm.fight.winner === 'blue' }},[_c('div',{staticClass:"fighter-image",class:{
          'large-screen': _vm.breakpoint.smAndUp,
          'loser-image': _vm.fight.winner === 'red',
        },style:({
          'background-image': `url(${
            _vm.blueAthleteImage ? _vm.blueAthleteImage : _vm.missingImage
          })`,
        })}),(_vm.fight.winner === 'blue')?_c('div',{staticClass:"win-indicator"},[_vm._v("Winner")]):_vm._e()])]),_c('div',{staticClass:"color-indicators"},[_c('div',{staticClass:"red"}),_c('div',{staticClass:"blue"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }