<template>
  <div>
    <Header :user="user" />

    <div id="alerts">
      <v-alert
        :key="index"
        v-for="(alert, index) in alerts"
        :type="alert.type"
        dismissible
        >{{ alert.message }}

        <template v-slot:close>
          <v-btn
            icon
            v-on:click="removeAlert(index)"
            class="alert__dismiss-btn"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </v-alert>
    </div>

    <v-main
      id="main-content"
      :class="{ 'main-content--full-screen': isFullScreen, 'main-content--small': breakpoint.smAndDown }"
    >
      <router-view
        v-if="!loading"
        :user="user"
        :alert="alert"
        @fullScreen="goFullScreen"
      />
    </v-main>
    <Footer v-if="breakpoint.smAndDown" />
  </div>
</template>
<script>
import firebase from "firebase";

import Header from "../components/authenticated/Header";
import Footer from "../components/authenticated/Footer";
import { handleSignIn } from "../services/utils";
import { init } from '../services/analytics';

export default {
  name: "AuthenticatedLayout",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    breakpoint: {},
    loading: true,
    user: null,
    alerts: [],
    isFullScreen: false,
  }),
  methods: {
    alert(alert) {
      this.alerts.push(alert);
    },
    removeAlert(index) {
      this.alerts.splice(index, 1);
    },
    goFullScreen(isFullScreen) {
      this.isFullScreen = isFullScreen;
    },
  },
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;

    this.loading = true;

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        return handleSignIn(idToken);
      })
      .then((user) => {
        this.user = user;
        init(user)
      })
      .catch(function (error) {
        // Handle error
        console.error(error);
        this.$router.push("/login");
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style>
#main-content {
  padding-top: 0 !important;
  max-width: 800px;
  margin: auto;
  min-height: calc(100vh - 188px);
}

.main-content--full-screen {
  max-width: unset !important;
  margin: 0 20px !important;
}

#main-content.main-content--small {
  max-width: 600px;
}

#alerts {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.alert__dismiss-btn {
  margin: -16px -8px -16px 8px;
}
</style>