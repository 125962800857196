<template>
  <div id="events-container">
    <v-progress-circular
      id="events-loader"
      v-if="loading"
      :size="70"
      color="black"
      indeterminate
    ></v-progress-circular>
    <div v-else class="events-list">
      <v-dialog
        persistent
        v-model="showMessages"
        width="600"
        v-if="messages.length"
      >
        <v-card>
          <v-card-title class="modal-title">
            {{ messages[messageIndexToDisplay].title }}
          </v-card-title>

          <v-card-text class="message-body">
            <div
              v-html="messages[messageIndexToDisplay].body"
              class="modal-body"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="() => markAsRead(messages[messageIndexToDisplay])"
              class="modal-btn"
            >
              {{
                messages.length - 1 > messageIndexToDisplay ? "Next" : "Close"
              }}
            </v-btn>
            <v-btn
              elevation="2"
              color="primary"
              v-if="
                messages[messageIndexToDisplay].action &&
                messages[messageIndexToDisplay].action.href
              "
              :href="messages[messageIndexToDisplay].action.href"
              target="_blank"
              class="modal-btn message-action-btn"
            >
              {{ messages[messageIndexToDisplay].action.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="eventToday" class="my-10">
        <div class="today-title">TODAY</div>
        <EventCard
          :eventId="eventToday._id"
          :event="eventToday"
          :to="'/events/' + eventToday._id"
        />
        <div class="divider" />
      </div>

      <EventCard
        :key="index"
        v-for="(event, index) in events"
        :eventId="event._id"
        :event="event"
        :to="'/events/' + event._id"
      />
    </div>
  </div>
</template>
<script>
// import { isSameDay } from "date-fns";

import EventCard from "../../components/authenticated/EventCard";
import {
  getUpcomingEvents,
  getPastEvents,
  getUnreadMessages,
  markMessageAsRead,
} from "../../services/database";
import { scrollToPosition } from "../../services/scroll";

export default {
  name: "Events",
  components: {
    EventCard,
  },
  data: () => ({
    loading: false,
    page: 0,
    eventToday: null,
    events: [],
    isUpcomingEvents: true,
    messages: [],
    messageIndexToDisplay: 0,
    showMessages: true,
  }),
  watch: {
    "$route.query.type"() {
      this.events = [];
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      let getEventsPromise;
      switch (this.$route.query.type) {
        case "upcoming":
          this.isUpcomingEvents = true;
          getEventsPromise = getUpcomingEvents;
          break;
        case "past":
          this.isUpcomingEvents = false;
          getEventsPromise = getPastEvents;
          break;
        default:
          break;
      }

      Promise.all([getEventsPromise(), getUnreadMessages().catch(() => [])])
        .then(([events, unreadMessages]) => {
          this.messages = unreadMessages;
          this.messageIndexToDisplay = 0;

          // TODO need to fix this if we want to highlight todays events
          // const sixHoursInSeconds = 6 * 60 * 60;

          // const currentDateTimeInSeconds = Math.round(
          //   new Date().getTime() / 1000
          // );

          // if (
          //   currentDateTimeInSeconds - sixHoursInSeconds >=
          //   events[0].dateTimeSeconds
          // ) {
          //   this.eventToday = events[0];
          //   this.events = events.slice(1);
          // } else {
          //   this.eventToday = null;
          //   this.events = events;
          // }
          this.events = events;
        })
        .finally(() => {
          this.loading = false;

          scrollToPosition.bind(this)();
        });
    },
    markAsRead(message) {
      markMessageAsRead(message).then(() => {
        if (this.messageIndexToDisplay + 1 >= this.messages.length) {
          this.showMessages = false;
        } else {
          this.messageIndexToDisplay++;
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style>
#events-container {
  text-align: center;
}

#events-loader {
  margin-top: 80px;
}

.today-title {
  margin: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.divider {
  height: 2px;
  background-color: lightgray;
  width: 100%;
  margin-top: 36px;
}

.modal-title {
  font-size: 24px !important;
}

.modal-body {
  font-size: 18px !important;
}

.modal-btn {
  font-size: 16px !important;
}

.message-body {
  padding-bottom: 12px !important;
}

.message-action-btn {
  padding: 0 16px !important;
}
</style>