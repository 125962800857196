<template>
  <v-btn :disabled="loading" class="admin__btns" v-on:click="request()">{{label}}</v-btn>
</template>
<script>
import axios from "../../services/axios";

export default {
  name: 'AdminButton',
  props: {
    label: String,
    endpoint: String,
    axiosMethod: String
  },
  data: () => ({
    loading: false
  }),
  methods: {
    request() {
      this.loading = true
      axios[this.axiosMethod](this.endpoint).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style>
.admin__btns {
  margin: 10px;
}
</style>