<template>
  <div id="authenticated__header">
    <v-app-bar app color="black" dark>
      <v-toolbar-title>
        <v-btn text to="/" id="home-btn">
          <span class="ufc-font app-bar__ufc-text">UFC</span>
          <span class="predictor-font app-bar__predictor-text">PREDICTOR</span>
        </v-btn>
      </v-toolbar-title>

      <v-spacer v-if="breakpoint.mdAndUp && !noButtons" />
      <v-tabs
        v-if="breakpoint.mdAndUp && !noButtons"
        :slider-color="constants.ufcRed"
        slider-size="5"
        class="app-bar__tabs"
        optional
      >
        <v-tab
          :key="index"
          v-for="(tab, index) in tabs"
          :to="tab.path"
          exact
          >{{ tab.text }}</v-tab
        >
      </v-tabs>
      <v-spacer v-if="breakpoint.mdAndUp" />
      <v-btn id="admin-btn" link v-if="user && user.is_admin" to="/admin">Admin</v-btn>
      <!-- <v-btn v-if="breakpoint.mdAndUp" v-on:click="signOut">sign out</v-btn> -->
      <!-- TODO what to do for mobile -->
      <div id="user-menu" v-if="user">
        <v-menu offset-y v-if="breakpoint.mdAndUp">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar>
                <img :src="user.pictureURL" :alt="user.name" />
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(item, index) in userMenuItems"
              :key="index"
              :color="item.color"
              :to="item.path"
              v-on:click="item.action ? item.action() : () => {}"
            >
              <v-list-item-title :to="item.path" :color="item.color">
                <!-- <v-btn> -->
                {{ item.text }}
                <!-- </v-btn> -->
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-toolbar dense color="black" dark v-if="breakpoint.smAndDown">
      <v-tabs
        :slider-color="constants.ufcRed"
        slider-size="5"
        class="app-bar__tabs"
        optional
      >
        <v-tab
          :key="index"
          v-for="(tab, index) in tabs"
          :to="tab.path"
          exact
          >{{ tab.text }}</v-tab
        >
      </v-tabs>
    </v-toolbar>
  </div>
</template>
<script>
import firebase from "firebase";
import constants from "../../constants";

export default {
  name: "Header",
  props: {
    noButtons: Boolean,
    user: Object,
  },
  data: function () {
    return {
      constants,
      breakpoint: {},
      tabs: [
        {
          text: "Upcoming Events",
          path: { path: "/events", query: { type: "upcoming" } },
        },
        {
          text: "Past Events",
          path: { path: "/events", query: { type: "past" } },
        },
      ],
      userMenuItems: [
        // {
        //   text: "Purchases",
        //   path: "purchases",
        // },
        {
          text: "Sign out",
          color: "#FF0000",
          action: this.signOut,
        },
      ],
    };
  },
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.$router.push("/login");
        })
        .catch((error) => {
          // An error happened.
          console.error(error);
        });
    },
  },
};
</script>
<style>
#authenticated__header .v-tab--disabled {
  color: white;
  opacity: 1;
}
.v-toolbar__title {
  overflow: unset !important;
}

.app-bar__ufc-text {
  font-size: 37px;
}

.app-bar__predictor-text {
  font-size: 30px;
  margin-left: 10px;
}

.app-bar__tabs {
  margin-left: 20px;
}

.v-toolbar__content {
  justify-content: center;
}

#home-btn.v-btn:before {
  background-color: unset;
}

#admin-btn {
  margin-right: 10px;
}
</style>