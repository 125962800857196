<template>
  <v-btn
    color="black"
    dark
    fab
    @click="$emit('click')"
    class="back-button"
  >
    <v-icon>mdi-arrow-left</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "BackButton",
};
</script>
<style>
/* .back-button {
  top: 100px;
  left: 20px;
}

@media (max-width: 959px) {
  .back-button {
    top: 120px;
  }
} */
</style>