import Vue from 'vue'
import VueRouter from 'vue-router'

import { getCurrentUser } from '../services/utils'

import AuthenticatedLayout from '../layouts/Authenticated'

import AdminPredictions from '../views/admin/Predictions'

import Event from '../views/authenticated/Event'
import Events from '../views/authenticated/Events'
import Fight from '../views/authenticated/Fight'
import Fighters from '../views/authenticated/Fighters'
import Purchases from '../views/authenticated/Purchases'
import Analytics from '../views/authenticated/Analytics'
import { logEvent } from '../services/analytics'
import scrollPosition from '../services/scroll'

import Login from '../views/Login'
import Terms from '../views/Terms'
import Privacy from '../views/Privacy'

Vue.use(VueRouter)

const privateRoute = async (to, from, next) => {
  try {
    const user = await getCurrentUser();

    if (to.path !== '/login' && !user) {
      next('/login')
    } else if (to.path === '/login' && !!user) {
      next({ path: "/events", query: { type: "upcoming" } })
    } else if (to.path === '/' || to.path === '') {
      next({ path: "/events", query: { type: "upcoming" } })
    } else {
      next()
    }
  } catch (error) {
    console.error('failed to get current user', error)
    next('/login')
  }
}

const routes = [
  {
    path: '/',
    component: AuthenticatedLayout,
    beforeEnter: privateRoute,
    children: [
      {
        path: '/',
        beforeEnter: privateRoute,
      },
      {
        path: 'events',
        component: Events,
      }, {
        path: 'events/:eventId',
        component: Event,
      },
      {
        path: 'events/:eventId/fights/:fightId',
        component: Fight,
      },
      {
        path: 'events/:eventId/fights/:fightId/analytics',
        component: Analytics,
      },
      {
        path: 'fighters',
        component: Fighters,
      },
      {
        path: 'purchases',
        component: Purchases,
      },
    ],
  },
  {
    path: "/admin",
    component: AuthenticatedLayout,
    children: [
      {
        path: "",
        redirect: 'predictions'
      },
      {
        path: "predictions",
        component: AdminPredictions
      }
    ]
  },
  {
    path: '/login',
    name: "Login",
    component: Login,
  },
  {
    path: '/terms',
    name: "Terms",
    component: Terms
  },
  {
    path: '/privacy',
    name: "Privacy",
    component: Privacy
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  logEvent('screen_view', {
    firebase_screen: to.fullPath,
    // firebase_screen_class: screenClass
  });

  // only store if navigating forward
  // by checking paths
  const toCount = to.path.match(/\//g).length
  const fromCount = from.path.match(/\//g).length

  if (toCount > fromCount) {
    scrollPosition[from.path] = {
      y: document.documentElement.scrollTop
    }
  } else if (toCount < fromCount) {
    if (scrollPosition[from.path]) scrollPosition[from.path].y = 0
  }



})


export default router
