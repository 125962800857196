<template>
  <div class="container--outer">
    <div class="container--inner">
      <div
        class="vertical-line"
        :style="{ height: verticalHeight + 'px' }"
      ></div>
      <div
        class="horizontal-line"
        :style="{
          width: width + 'px',
          marginTop: horizontalMarginTop + 'px',
        }"
      ></div>
      <div
        class="vertical-line"
        :style="{ height: verticalHeight + 'px' }"
      ></div>
      <div
        ref="textElement"
        class="text"
        :style="{
          paddingLeft: textPadding + 'px',
          paddingRight: textPadding + 'px',
          fontSize: textFontSize + 'px',
          top: textTop + 'px',
          left: textLeft + 'px',
        }"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
const WIDTH = 150;

export default {
  name: "Reach",
  props: {
    isBig: Boolean,
    text: String,
  },
  data: function () {
    return {
      isMounted: false,
      textFontSize: 24,
      textPadding: 10,
      verticalHeight: 30,
      width: this.isBig ? WIDTH : WIDTH * 0.75,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    textTop() {
      if (!this.isMounted) return;
      return this.textFontSize / 30; // ?
    },
    textLeft() {
      if (!this.isMounted) return;
      const textWidth = this.$refs.textElement.offsetWidth
      return this.width / 2 + this.textPadding / 2 - textWidth / 2;
    },
    horizontalMarginTop() {
      return this.verticalHeight / 2;
    },
  },
};
</script>
<style scoped>
.container--outer {
  display: flex;
  align-items: flex-end;
}

.container--inner {
  position: relative;
  display: flex;
}

.horizontal-line {
  border-top: 2px solid black;
}

.vertical-line {
  border-right: 2px solid black;
}

.text {
  position: absolute;
  background: white;
}
</style>
