<template>
  <div class="title">
    <div class="ufc-font login-header__ufc-text">UFC</div>
    <div class="predictor-font login-header__predictor-text">PREDICTOR</div>
  </div>
</template>

<script>
export default {
  name: "LoginHeader",
};
</script>

<style scoped>
.title {
  text-align: center;
  padding: 20px;
  /* background-color: #d20a0a; UFC RED */
  background-color: black;
  color: white;
  margin-bottom: 80px;
}
.login-header__ufc-text {
  line-height: 50px;
  font-size: 60px;
}
.login-header__predictor-text {
  line-height: 50px;
  font-size: 45px;
}
</style>