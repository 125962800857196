<template>
  <div>
    <div :style="{ textAlign: fromRight ? 'right' : 'left' }">
      {{ value }}<span>%</span>
    </div>
    <div class="containter">
      <div
        class="value"
        :style="{
          width: `${value}%`,
          backgroundColor: color,
          marginLeft: fromRight ? 'auto' : 0,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Percentage",
  props: {
    value: Number,
    color: String,
    fromRight: Boolean,
  },
};
</script>
<style>
.containter {
  background-color: lightgray;
  height: 6px;
  width: 100%;
  height: 12px;
  border-radius: 2px;
}

.value {
  height: 100%;
  border-radius: 2px;

}
</style>