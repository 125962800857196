<template>
  <v-card class="fight-card" v-on:click="() => navigate()">
    <div class="fight-overview">
      <div
        class="red fighter-container"
        :class="{ winner: fight.winner === 'red' }"
      >
        <div
          class="fighter-image"
          :class="{
            'large-screen': breakpoint.smAndUp,
            'loser-image': fight.winner === 'blue',
          }"
          :style="{
            'background-image': `url(${
              redAthleteImage ? redAthleteImage : missingImage
            })`,
          }"
        ></div>
        <div class="win-indicator" v-if="fight.winner === 'red'">Winner</div>
      </div>
      <div
        class="card__center-text"
        :class="{ 'card__center-text--large': breakpoint.mdAndUp }"
      >
        <div class="fight-card__additional-info">
          {{
            fight.weightClass
              .replace("UFC", "")
              .replace("Bout", "")
              .replace("Women's", "")
              .trim()
          }}
        </div>
        <div
          class="bout__text"
          :class="{ 'bout__text--large': breakpoint.mdAndUp }"
        >
          <div
            class="fighter-name red-fighter"
            :class="{ 'fighter-name--small-font': breakpoint.xs }"
          >
            <div
              class="fighter-name-split__container"
              :class="{ 'prediction red': isRedPredictedToWin }"
              :style="{
                padding: breakpoint.mdAndUp ? '8px 16px' : '4px',
                'text-align': breakpoint.mdAndUp ? 'unset' : 'center',
              }"
            >
              <div v-if="breakpoint.mdAndUp">
                <div>
                  {{ fight.fighters.red.name.given }}
                </div>
                <div>
                  {{ fight.fighters.red.name.family }}
                </div>
              </div>
              <div v-else>
                {{ fight.fighters.red.name.given }}
                {{ fight.fighters.red.name.family }}
              </div>

              <slot name="post-red-name"></slot>

              <div v-if="isRedPredictedToWin" class="prediction-text">
                predicted win
              </div>
            </div>
          </div>
          <div class="vs-text">vs</div>
          <div
            class="fighter-name blue-fighter"
            :class="{ 'fighter-name--small-font': breakpoint.xs }"
          >
            <div
              class="fighter-name-split__container"
              :class="{ 'prediction blue': isBluePredictedToWin }"
              :style="{
                padding: breakpoint.mdAndUp ? '8px 16px' : '4px',
                'text-align': breakpoint.mdAndUp ? 'unset' : 'center',
              }"
            >
              <div v-if="breakpoint.mdAndUp">
                <div>
                  {{ fight.fighters.blue.name.given }}
                </div>
                <div>
                  {{ fight.fighters.blue.name.family }}
                </div>
              </div>
              <div v-else>
                {{ fight.fighters.blue.name.given }}
                {{ fight.fighters.blue.name.family }}
              </div>

              <slot name="post-blue-name"></slot>

              <div v-if="isBluePredictedToWin" class="prediction-text">
                predicted win
              </div>
            </div>
          </div>
        </div>
        <div v-if="isNotEnoughDataForPrediction" class="not-enough-data">
          Not enough fights in the UFC for a prediction
        </div>
        <div
          v-if="showDetails && fight.methodOfVictory"
          class="fight-card__additional-info fight-card__additional-info--bottom"
        >
          <div>{{ fight.methodOfVictory }}</div>
        </div>
      </div>

      <div
        class="blue fighter-container"
        :class="{ winner: fight.winner === 'blue' }"
      >
        <div
          class="fighter-image"
          :class="{
            'large-screen': breakpoint.smAndUp,
            'loser-image': fight.winner === 'red',
          }"
          :style="{
            'background-image': `url(${
              blueAthleteImage ? blueAthleteImage : missingImage
            })`,
          }"
        ></div>
        <div class="win-indicator" v-if="fight.winner === 'blue'">Winner</div>
      </div>
    </div>
    <div class="color-indicators">
      <div class="red"></div>
      <div class="blue"></div>
    </div>
  </v-card>
</template>
<script>
import unknownMaleImage from "../../assets/images/unknown_male_fighter.png";
import unknownFemaleImage from "../../assets/images/unknown_female_fighter.png";

export default {
  name: "FightCard",
  props: {
    event: Object,
    fight: Object,
    index: Number,
    showDetails: {
      type: Boolean,
      default: true,
    },
    requiresPurchase: Boolean,
  },
  data: () => ({
    breakpoint: {},
  }),
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;
  },
  computed: {
    missingImage() {
      if (this.fight.weightClass.toUpperCase().includes("WOMEN")) {
        return unknownFemaleImage;
      } else {
        return unknownMaleImage;
      }
    },
    redAthleteImage() {
      if (this.fight.fighters.red.imageURL) {
        return this.fight.fighters.red.imageURL.startsWith("http")
          ? this.fight.fighters.red.imageURL
          : "https://ufc.com" + this.fight.fighters.red.imageURL;
      }

      return null;
    },
    blueAthleteImage() {
      if (this.fight.fighters.blue.imageURL) {
        return this.fight.fighters.blue.imageURL.startsWith("http")
          ? this.fight.fighters.blue.imageURL
          : "https://ufc.com" + this.fight.fighters.blue.imageURL;
      }

      return null;
    },
    isRedPredictedToWin() {
      if (this.fight.prediction) {
        return (
          this.fight.prediction.red_fighter_name == this.fight.prediction.winner
        );
      }

      return false;
    },
    isBluePredictedToWin() {
      if (this.fight.prediction) {
        return (
          this.fight.prediction.blue_fighter_name ==
          this.fight.prediction.winner
        );
      }

      return false;
    },
    isNotEnoughDataForPrediction() {
      console.log("this", this);
      if (this.event && !!this.event.predicted && !this.fight.prediction) {
        return true;
      }

      return false;
    },
  },
  methods: {
    navigate() {
      if (this.requiresPurchase) {
        this.$emit("show-purchase");
      } else {
        if (this.index >= 0) {
          this.$router.push(
            `/events/${this.$route.params.eventId}/fights/${this.index}`
          );
        }
      }
    },
  },
};
</script>
<style>
.fight-card {
  margin: 5px;
  margin-bottom: 10px;
}

.fight-overview {
  display: flex;
}

.bout__text {
  display: flex;
  flex-direction: column;
}

.fighter-name.red-fighter {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.fighter-name.blue-fighter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.bout__text--large {
  flex-direction: row;
  font-size: 22px;
}

.bout__text--large .fighter-name.red-fighter {
  align-items: center;
  justify-content: left;
  text-align: left;
}

.card__center-text {
  padding: 16px;
  flex: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card__center-text--large {
  padding: 40px 16px;
}

.bout__text--large .fighter-name.blue-fighter {
  align-items: center;
  justify-content: right;
  text-align: right;
}

.fighter-name-split__container {
  flex: 1;
}

.fight-card__additional-info {
  font-weight: bold;
  color: #7d7d7d;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
}

.fight-card__additional-info--bottom {
  margin-bottom: 0px;
  margin-top: 16px;
}

.vs-text {
  flex: 1;
  font-weight: bold;
  color: #7d7d7d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fighter-name {
  flex: 2;
  font-weight: bold;
  text-transform: uppercase;
}

.fighter-name--small-font {
  font-size: 14px;
}

.v-application .red.fighter-container,
.v-application .blue.fighter-container {
  background-color: unset !important;
  border-color: unset !important;
}

.fighter-container {
  flex: 1;
  position: relative;
}

/* .fighter-container.winner {
  border: solid 3px;
  border-radius: 4px;
} */

.win-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 5px;
  font-weight: bold;
  background-color: gold;
  color: black;
  text-align: center;
}

.red .win-indicator {
  border-top-right-radius: 4px;
}

.blue .win-indicator {
  border-top-left-radius: 4px;
}

.fighter-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.fighter-image.large-screen {
  min-height: 150px;
}

.color-indicators {
  display: flex;
}

.color-indicators .red,
.color-indicators .blue {
  flex: 1;
  height: 6px;
}

.color-indicators .red {
  background-color: rgb(210, 10, 10) !important;
}

.color-indicators .blue {
  background-color: rgb(22, 85, 159) !important;
}

.prediction {
  border-radius: 20px;
  color: white;
}

.prediction .prediction-text {
  font-size: 12px;
  font-weight: unset;
  border-radius: 20px;
}

.prediction.red {
  background-color: rgb(210, 10, 10) !important;
}

.prediction.blue {
  background-color: rgb(22, 85, 159) !important;
}

.not-enough-data {
  padding: 8px;
  border-radius: 10px;
  background-color: #5f5656;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.loser-image {
  opacity: 0.3;
}
</style>