<template>
  <div>
    <div class="comparison-title">{{ title }}</div>
    <div class="d-flex">
      <Percentage
        :value="Math.round((redValue / redTotal) * 100)"
        color="rgb(210, 10, 10)"
        class="flex"
      />
      <Percentage
        :value="Math.round((blueValue / blueTotal) * 100)"
        color="rgb(22, 85, 159)"
        fromRight
        class="ml-2 flex"
      />
    </div>
  </div>
</template>
<script>
import Percentage from "./Percentage.vue";

export default {
  name: "PercentageComparison",
  props: {
    title: String,
    redValue: { type: Number, default: 0 },
    redTotal: { type: Number, default: 1 },
    blueValue: { type: Number, default: 0 },
    blueTotal: { type: Number, default: 1 },
  },
  components: {
    Percentage,
  },
};
</script>
<style>
.comparison-title {
  margin-bottom: -24px;
}
</style>