<template>
  <v-footer dark id="footer">
    <v-row justify="center" no-gutters>
      <!-- <v-btn v-for="link in links" :key="link" color="white" text rounded class="my-2">{{ link }}</v-btn> -->
      <v-col cols="12">
        <div class="sign-out-text" v-on:click="signOut">Sign out</div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import firebase from "firebase";

export default {
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.$router.push("/login");
        })
        .catch((error) => {
          // An error happened.
          console.error(error);
        });
    },
  },
}
</script>
<style>
#footer {
  background-color: black;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sign-out-text {
  text-align: right;
}
</style>