<template>
  <div id="admin__container">
    <div :key="index" v-for="(btn, index) in buttons">
      <AdminButton :key="index" :label="btn.label" :endpoint="btn.endpoint" :axiosMethod="btn.axiosMethod"/>
    </div>
    <v-divider />
    <h1>Upcoming Events</h1>
    <div :key="index" v-for="(event, index) in upcomingEvents">
      <div class="admin__event">
        <div>{{event.name}}</div>
        <AdminButton :key="index" label="Delete event" :endpoint="getDelete(event.id)" axiosMethod="delete"/>
        <AdminButton :key="index" label="Delete fights" :endpoint="getDeleteFights(event.id)" axiosMethod="delete"/>
        <AdminButton :key="index" label="Reload fights" :endpoint="getReload(event.id)" axiosMethod="get"/>
        <AdminButton :key="index" label="Predict fights" :endpoint="getPredict(event.id)" axiosMethod="get"/>
        <AdminButton :key="index" label="Delete Predictions" :endpoint="getDeletePredictions(event.id)" axiosMethod="delete"/>
      </div>
      <v-divider />
    </div>

    <h1>Past Events</h1>
    <div :key="index" v-for="(event, index) in pastEvents">
      <div class="admin__event">
        <div>{{event.name}} <AdminButton :key="index" label="Get results" :endpoint="getResults(event.id)" axiosMethod="get"/></div>
        <AdminButton :key="index" label="Delete event" :endpoint="getDelete(event.id)" axiosMethod="delete"/>
        <AdminButton :key="index" label="Delete fights" :endpoint="getDeleteFights(event.id)" axiosMethod="delete"/>
        <AdminButton :key="index" label="Reload fights" :endpoint="getReload(event.id)" axiosMethod="get"/>
        <AdminButton :key="index" label="Predict fights" :endpoint="getPredict(event.id)" axiosMethod="get"/>
        <AdminButton :key="index" label="Delete Predictions" :endpoint="getDeletePredictions(event.id)" axiosMethod="delete"/>
      </div>

      <v-divider />
    </div>
  </div>
</template>
<script>
import { getPastEvents, getUpcomingEvents } from "../../services/database";

import AdminButton from '../../components/authenticated/AdminButton'

export default {
  name: "Admin",
  components: {
    AdminButton,
  },
  methods: {
    getResults(eventId) {
      return "/api/events/" + eventId + '/results'
    },
    getDelete(eventId) {
      return "/api/events/" + eventId
    },
    getDeleteFights(eventId) {
      return "/api/events/" + eventId + '/fights'
    },
    getReload(eventId) {
      return "/api/events/" + eventId + '/reload_fights'
    },
    getPredict(eventId) {
      return "/api/events/" + eventId + '/predict'
    },
    getDeletePredictions(eventId) {
      return "/api/events/" + eventId + '/predictions'
    },
  },
  data: function () {
    return {
      upcomingEvents: [],
      pastEvents: [],
      buttons: [
        {
          label: "Load upcoming events",
          endpoint: "/api/events/upcoming",
          axiosMethod: 'get'
        },
        {
          label: "Update past events",
          endpoint: '/api/events/past',
          axiosMethod: 'get'
        },
        {
          label: "Reload all past events",
          endpoint: "/api/events/reload_past",
          axiosMethod: 'get'
        },
        {
          label: "Load Event Images",
          endpoint: "/api/images/events",
          axiosMethod: 'get'
        },
        {
          label: "Load Fighters",
          endpoint: "/api/fighters/load",
          axiosMethod: 'get'
        },
        {
          label: "Load Fighter Images",
          endpoint: "/api/images/fighters",
          axiosMethod: 'get'
        },
        {
          label: "Backup events",
          endpoint: "/api/backup/events",
          axiosMethod: 'get',
        },
        {
          label: "Backup fighters",
          endpoint: "/api/backup/fighters",
          axiosMethod: 'get'
        },
      ],
    };
  },
  mounted() {
    getUpcomingEvents().then((events) => {
      this.upcomingEvents = events;
    });

    getPastEvents().then((events) => {
      this.pastEvents = events;
    });
  },
};
</script>
<style>
#admin__container {
  margin-top: 30px;
}

.admin__event {
  padding: 20px 0;
}
</style>