<template>
  <div id="fight-container">
    <v-progress-circular
      id="events-loader"
      v-if="loading"
      :size="70"
      color="black"
      indeterminate
    ></v-progress-circular>

    <div v-if="!loading">
      <v-dialog
        v-model="showPurchaseAnalytics"
        width="500px"
        overlay-opacity="0.9"
      >
        <PurchaseAnalytics />
      </v-dialog>

      <div
        class="title-area text-left"
        :class="{ 'd-flex': breakpoint.smAndUp }"
      >
        <BackButton
          v-if="breakpoint.smAndUp"
          @click="$router.go(-1)"
          class="ml-2 mr-6"
        />

        <div class="event-title">
          <div class="event-title-number">
            {{ ufcEventNumber }}: {{ ufcFighterTitle }}
          </div>
          <div>
            {{ fight.fighters.red.name.family }} vs
            {{ fight.fighters.blue.name.family }}
          </div>
        </div>
        <div
          class="analytics-btn-container"
          :class="{ 'text-right': breakpoint.smAndUp }"
        >
          <v-btn color="black" dark @click="goToAnalytics"
            >Analytics <v-icon dark class="ml-2">mdi-arrow-right</v-icon></v-btn
          >
        </div>
      </div>
      <div
        id="fight-card__container"
        ref="fightCardContainer"
        class="fight-page__fight-card"
        :class="{ 'fight-page__fight-card--mobile': breakpoint.smAndDown }"
      >
        <FightCard :fight="fight">
          <template v-slot:post-red-name>
            <div class="fighter-win-loss-draw">
              ({{ fight.fighters.red.wins }}-{{ fight.fighters.red.losses }}-{{
                fight.fighters.red.draws
              }})
            </div>
          </template>
          <template v-slot:post-blue-name>
            <div class="fighter-win-loss-draw">
              ({{ fight.fighters.blue.wins }}-{{
                fight.fighters.blue.losses
              }}-{{ fight.fighters.blue.draws }})
            </div>
          </template>
        </FightCard>

        <div class="prediction-confidence" v-if="predictionRating">
          Confidence:
          <span
            class="prediction-confidence-rating"
            :class="`rating-${predictionRating}`"
            >{{ predictionRating }}</span
          >
          ({{ predictionConfidence }})%
        </div>
      </div>

      <v-tabs color="red" v-model="tab" centered>
        <v-tabs-slider></v-tabs-slider>

        <v-tab :ripple="false" key="History">History</v-tab>
        <v-tab :ripple="false" key="Wins">Wins</v-tab>
        <v-tab :ripple="false" key="Striking">Striking</v-tab>
        <v-tab :ripple="false" key="Grappling">Grappling</v-tab>
        <v-tab :ripple="false" key="Physique">Physique</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mx-2 mt-4">
        <v-tab-item key="History">
          <div class="d-flex">
            <div class="flex history-column mr-2">
              <PastFight
                v-for="(fight, index) in fight.fighters.red.pastFights"
                :key="index"
                :fight="fight"
                :left="true"
              />
              <div
                v-if="
                  !fight.fighters.red.pastFights ||
                  fight.fighters.red.pastFights.length === 0
                "
              >
                No data
              </div>
            </div>
            <div class="flex history-column">
              <PastFight
                v-for="(fight, index) in fight.fighters.blue.pastFights"
                :key="index"
                :fight="fight"
              />

              <div
                v-if="
                  !fight.fighters.blue.pastFights ||
                  fight.fighters.blue.pastFights.length === 0
                "
              >
                No data
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="Wins">
          <PercentageComparison
            title="KO / TKO"
            :redValue="fight.fighters.red.knockoutOrTechnicalKnockout"
            :redTotal="fight.fighters.red.wins"
            :blueValue="fight.fighters.blue.knockoutOrTechnicalKnockout"
            :blueTotal="fight.fighters.blue.wins"
          />
          <PercentageComparison
            title="Submissions"
            :redValue="fight.fighters.red.submission"
            :redTotal="fight.fighters.red.wins"
            :blueValue="fight.fighters.blue.submission"
            :blueTotal="fight.fighters.blue.wins"
            class="mt-8"
          />

          <PercentageComparison
            title="Decisions"
            :redValue="fight.fighters.red.decision"
            :redTotal="fight.fighters.red.wins"
            :blueValue="fight.fighters.blue.decision"
            :blueTotal="fight.fighters.blue.wins"
            class="mt-8"
          />
        </v-tab-item>
        <v-tab-item key="Striking">
          <PercentageComparison
            title="Landed (total)"
            :redValue="fight.fighters.red.significantStrikesLanded"
            :redTotal="fight.fighters.red.significantStrikesAttempted"
            :blueValue="fight.fighters.blue.significantStrikesLanded"
            :blueTotal="fight.fighters.blue.significantStrikesAttempted"
          />

          <PercentageComparison
            title="Standing"
            :redValue="fight.fighters.red.significantStrikesStanding"
            :redTotal="fight.fighters.red.significantStrikesAttempted"
            :blueValue="fight.fighters.blue.significantStrikesStanding"
            :blueTotal="fight.fighters.blue.significantStrikesAttempted"
            class="mt-8"
          />

          <PercentageComparison
            title="Clinch"
            :redValue="fight.fighters.red.significantStrikesClinch"
            :redTotal="fight.fighters.red.significantStrikesAttempted"
            :blueValue="fight.fighters.blue.significantStrikesClinch"
            :blueTotal="fight.fighters.blue.significantStrikesAttempted"
            class="mt-8"
          />

          <PercentageComparison
            title="Ground"
            :redValue="fight.fighters.red.significantStrikesGround"
            :redTotal="fight.fighters.red.significantStrikesAttempted"
            :blueValue="fight.fighters.blue.significantStrikesGround"
            :blueTotal="fight.fighters.blue.significantStrikesAttempted"
            class="mt-8"
          />
        </v-tab-item>
        <v-tab-item key="Grappling">
          <PercentageComparison
            title="Takedowns"
            :redValue="fight.fighters.red.takedownsLanded"
            :redTotal="fight.fighters.red.takedownsAttempted"
            :blueValue="fight.fighters.blue.takedownsLanded"
            :blueTotal="fight.fighters.blue.takedownsAttempted"
          />
        </v-tab-item>
        <v-tab-item key="Physique">
          <PhysiqueComparison
            component="Height"
            :red="fight.fighters.red.height"
            :blue="fight.fighters.blue.height"
          />
          <PhysiqueComparison
            component="Reach"
            :red="fight.fighters.red.reach"
            :blue="fight.fighters.blue.reach"
            class="mt-8"
          />
          <PhysiqueComparison
            component="Reach"
            title="Leg Reach"
            :red="fight.fighters.red.legReach"
            :blue="fight.fighters.blue.legReach"
            class="my-8"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
// import { loadStripe } from "@stripe/stripe-js";

import FightCard from "../../components/authenticated/FightCard";
import PastFight from "../../components/authenticated/PastFight.vue";
import PhysiqueComparison from "../../components/authenticated/PhysiqueComparison.vue";
import BackButton from "../../components/authenticated/BackButton.vue";

import { getEvent, getFight } from "../../services/database";
import PercentageComparison from "../../components/authenticated/PercentageComparison.vue";
import { getPrice } from "../../services/database";

// import { analytics } from "../../firebase";

export default {
  name: "Fight",
  props: {
    user: Object,
    alert: Function,
  },
  components: {
    FightCard,
    PastFight,
    PhysiqueComparison,
    PercentageComparison,
    BackButton,
  },
  data: function () {
    return {
      loading: true,
      breakpoint: {},
      tab: "Physique",
      hasUserPurchased: false,
      showPurchaseAnalytics: false,
      event: {},
      fight: {
        red: {},
        blue: {},
        bout: "",
      },
    };
  },
  computed: {
    predictionConfidence() {
      if (
        this.fight.predictions &&
        this.fight.predictions.red &&
        this.fight.predictions.blue
      ) {
        const predictedFighterConfidence =
          this.fight.predictions.red > this.fight.predictions.blue
            ? this.fight.predictions.red
            : this.fight.predictions.blue;

        return Math.round(predictedFighterConfidence * 100);
      }

      return null;
    },
    predictionRating() {
      if (
        this.fight.predictions &&
        this.fight.predictions.red &&
        this.fight.predictions.blue
      ) {
        const predictedFighterConfidence =
          this.fight.predictions.red > this.fight.predictions.blue
            ? this.fight.predictions.red
            : this.fight.predictions.blue;

        let confidenceRating = "Low";
        if (
          predictedFighterConfidence >= 0.55 &&
          predictedFighterConfidence < 0.65
        )
          confidenceRating = "Medium";
        if (predictedFighterConfidence >= 0.65) confidenceRating = "High";

        return confidenceRating;
      }

      return null;
    },
    redAthleteImage() {
      if (this.fight.fighters.red.imageURL) {
        return this.fight.fighters.red.imageURL.startsWith("http")
          ? this.fight.fighters.red.imageURL
          : "https://ufc.com" + this.fight.fighters.red.imageURL;
      }

      return null;
    },
    blueAthleteImage() {
      if (this.fight.fighters.blue.imageURL) {
        return this.fight.fighters.blue.imageURL.startsWith("http")
          ? this.fight.fighters.blue.imageURL
          : "https://ufc.com" + this.fight.fighters.blue.imageURL;
      }

      return null;
    },
    ufcEventNumber() {
      if (this.event && this.event.name) {
        const ppvMatch = this.event.name.match("UFC [0-9]{3}");
        const fightNightMatch = this.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match ? match[0] : "";
      }

      return "";
    },
    ufcFighterTitle() {
      if (this.event && this.event.name) {
        const ppvMatch = this.event.name.match("UFC [0-9]{3}");
        const fightNightMatch = this.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match ? this.event.name.replace(match[0], "") : this.event.name;
      }

      return "";
    },
  },
  methods: {
    goToAnalytics() {
      // if (this.hasUserPurchased) {
      this.$router.push(this.$route.params.fightId + "/analytics");
      // } else {
      //   this.showPurchaseAnalytics = true;
      // }
    },
  },
  watch: {},
  created() {},
  destroyed() {},
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;

    this.loading = true;

    if (this.$route.query.payment == "success") {
      const purchase = this.user.purchases.find(
        (purchase) => purchase.items[0].item_id == this.$route.params.eventId
      );

      // £3.99 is stored in the db as 399 so needs to be converted
      purchase.items[0].value = purchase.items[0].value / 100;

      // analytics.logEvent("purchase", purchase);

      this.$router.replace({
        path: `/events/${this.$route.params.eventId}/fights/${this.$route.params.fightId}`,
      });
    }

    // load page
    const eventPromise = getEvent(this.$route.params.eventId);
    const fightPromise = getFight(
      this.$route.params.eventId,
      this.$route.params.fightId
    );

    // const hasUserPurchasedPromise = hasUserPurchased(
    //   process.env.VUE_APP_STRIPE_PRODUCT_ANALYTICS
    // );
    // const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_API_KEY);

    Promise.all([
      eventPromise,
      fightPromise,
      // hasUserPurchasedPromise,
      // stripePromise,
    ])
      .then(([event, fight]) => {
        this.event = event;
        this.fight = fight;
        // this.stripe = stripe;
        // this.hasUserPurchased = hasUserPurchased;

        getPrice(process.env.VUE_APP_STRIPE_PRODUCT_ANALYTICS).then((price) =>
          console.log("price", price)
        );
      })
      .catch((error) => {
        if (error.code == "permission-denied") {
          this.alert({
            type: "error",
            message: "You do not have access to this fight",
          });

          this.$router.push(`/events/${this.$route.params.eventId}`);
        } else {
          this.alert({
            type: "error",
            message: "failed fetching fight data. Please try again later",
          });
          console.error(error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style>
#fight-container {
  text-align: center;
}

.fight-page__fight-card {
  top: 64px;
  margin: 3px;
}

.fight-page__fight-card--mobile {
  top: 56px;
  margin: 3px;
}

.fighter-win-loss-draw {
  font-size: 14px;
  font-weight: normal;
}

.prediction-confidence {
  border-bottom: 2px solid #d3d2d2;
  padding-top: 12px;
  padding-bottom: 18px;
  margin: 6px 0;
}

.prediction-confidence-rating {
  padding: 4px;
}

.rating-Low {
  background-color: red;
}

.rating-Medium {
  background-color: orange;
}

.rating-High {
  background-color: green;
}

.v-slide-group__prev {
  display: none !important;
}

.history-column {
  flex: 1;
}

.analytics-btn-container {
  flex: 1;
}
</style>