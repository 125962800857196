<template>
  <div>
    <Header />
    <div id="login__app-description">
      <div>
        Over 25 years of fight data analysed using Artificial Intelligence to
        give you accurate fight predictions.
      </div>
      <div>Sign up now for free!</div>
    </div>

    <div id="login"></div>

    <div class="text-center mt-8">
      Sign in to view predictions for the upcoming event:
    </div>
    <EventCard
      class="next-fight-preview"
      v-if="nextEvent"
      :eventId="nextEvent.id"
      :to="'/events/' + nextEvent._id"
      :event="nextEvent"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { init, logEvent } from '../services/analytics'

import Header from "../components/login/Header.vue";
import EventCard from "../components/authenticated/EventCard.vue";
import { getNextEvent } from "../services/database";

export default {
  name: "Login",
  components: {
    Header,
    EventCard,
  },
  data: function () {
    return {
      nextEvent: null,
    };
  },
  async mounted() {
    this.nextEvent = await getNextEvent();

    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());

    const uiConfig = {
      callbacks: {
        signInFailure() {
          console.error('sign in failed')
        },
        signInSuccessWithAuthResult(authResult) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          init(authResult)
          logEvent("login", {
            method: authResult.additionalUserInfo.providerId,
          });

          return true;
        },
        // uiShown () {
        //   // The widget is rendered.
        //   // Hide the loader.
        //   document.getElementById("loader").style.display = "none";
        // },
      },
      signInOptions: [
        {
          provider:
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          requireDisplayName: false,
          buttonColor: "#d20a0a",
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: "select_account",
          },
        },
      ],
      // signInSuccessUrl: "#/events?type=upcoming",
      signInSuccessUrl: "#/events?type=upcoming",
      // signInFlow: 'redirect',
      signInFlow: process.env.NODE_ENV == 'development' ? 'popup' : 'redirect',
      tosUrl: "#/terms",
      privacyPolicyUrl: "#/privacy",
    };

    ui.start("#login", uiConfig);
  },
};
</script>
<style>
#login__app-description {
  text-align: center;
  margin-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
}

.next-fight-preview {
  max-width: 600px;
  margin: auto;
}
</style>