<template>
  <v-card class="past-fight">
    <div
      v-if="left && this.$vuetify.breakpoint.smAndUp"
      class="fighter-image__container"
      :style="`background-image: url(${
        fight.fighterColour == 'red'
          ? fight.blueFighter.image
          : fight.redFighter.image
      })`"
    />
    <div class="details">
      <div
        class="win-loss-indicator"
        :class="fight.outcome.replace(/ /g, '-')"
        v-if="this.$vuetify.breakpoint.smAndUp"
      >
        {{ fight.outcome.toUpperCase() }}
      </div>
      <div
        class="fighter-image__container"
        :style="`background-image: url(${
          fight.fighterColour == 'red'
            ? fight.blueFighter.image
            : fight.redFighter.image
        }); width: 100%; height: 100px;`"
        v-if="this.$vuetify.breakpoint.xs"
      />
      <div
        class="win-loss-indicator"
        :class="{ win: fight.didWin, loss: !fight.didWin }"
        v-if="this.$vuetify.breakpoint.xs"
      >
        {{ fight.didWin ? "WIN" : "LOSS" }}
      </div>
      <div class="fight-against__text">
        {{
          fight.fighterColour == "red"
            ? fight.blueFighter.familyName
            : fight.redFighter.familyName
        }}
      </div>
      <div class="fight-date">{{ fightDate }}</div>
    </div>
    <div
      v-if="!left && this.$vuetify.breakpoint.smAndUp"
      class="fighter-image__container"
      :style="`background-image: url(${
        fight.fighterColour == 'red'
          ? fight.blueFighter.image
          : fight.redFighter.image
      })`"
    />
  </v-card>
</template>
<script>
import { format } from "date-fns";

export default {
  name: "PastFight",
  props: {
    fight: Object,
    left: Boolean,
  },
  computed: {
    fightDate: function () {
      let dateFormat = "MMM do";
      const date = new Date(null);
      date.setSeconds(this.fight.dateTimeSeconds);

      const currentYear = new Date().getFullYear();
      const fightYear = date.getFullYear();

      if (currentYear != fightYear) {
        dateFormat = "MMM do yyyy";
      }

      return format(date, dateFormat);
    },
  },
};
</script>
<style>
.fight-against__text {
  font-size: 20px;
  font-weight: bold;
}

.details {
  flex: 2;
}

.fight-date {
  font-size: 14px;
  color: #343434;
  margin-bottom: 8px;
}

.past-fight {
  /* padding: 4px; */
  margin: 8px 2px;
  display: flex;
  flex-direction: row;
}

.win-loss-indicator {
  padding: 8px;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  margin: 4px;
}

.win-loss-indicator.win {
  background-color: rgb(9, 133, 9);
}

.win-loss-indicator.loss {
  background-color: rgb(210, 10, 10);
}

.win-loss-indicator.draw, .win-loss-indicator.no-contest {
  background-color: rgb(60, 60, 60);
}

.past-fight .fighter-image {
  max-width: 150px;
  max-height: auto;
}

.fighter-image__container {
  flex: 1;
  background-position-x: center;
  background-size: cover;
  background-position-y: center;
}
</style>