import firebase from "firebase"
import axios from './axios';

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();

      if (user) resolve(user);
      else reject()
    }, reject)
  })
}

export function getUser(idToken) {
  axios.defaults.headers.common['id-token'] = idToken;
  return axios.get('/api/user').then(response => response.data)
}

export function handleSignIn(idToken) {
  axios.defaults.headers.common['id-token'] = idToken;
  return axios.post('/api/user/sign_in').then(response => response.data)
}

export function createCheckoutSession(event_id, fight_id) {
  return axios.post('/api/create_checkout_session', { event_id, fight_id }).then(response => response.data)
}

export function getPrice() {
  return axios.get('/api/premium/price').then(response => response.data)
}

export function capitalise(string) {
  const firstLetter = string.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = string.slice(1);
  const capitalisedWord = firstLetterCap + remainingLetters;
  return capitalisedWord
}