import firebase from "firebase";
import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyDfxAOeZVybPubJFTjbGQubyYdt5O3FrAU",
  // authDomain: "ufc-predictor.com",
  authDomain: "app.ufc-predictor.com",
  databaseURL: "https://ufc-predictor.firebaseio.com",
  projectId: "ufc-predictor",
  storageBucket: "ufc-predictor.appspot.com",
  messagingSenderId: "68798904995",
  appId: "1:68798904995:web:446c73cc358e91280b1d16",
  measurementId: "G-WYCT6KMJCV",
});

export const db = firebase.firestore()
export const storage = firebase.storage()
export const analytics = firebase.analytics();
