import { db } from '../firebase';
import axios from './axios';

export function getUnreadMessages() {
  return axios.get('/api/messages/unread').then(response => response.data)
}
export function markMessageAsRead(message) {
  return axios.post('/api/messages/read', { id: message._id }).then(response => response.data)
}

export function getNextEvent() {
  return axios.get('/api/events/next').then(response => response.data)
}

export function getUpcomingEvents() {
  return axios.get('/api/events/upcoming').then(response => response.data)
}

export function getPastEvents() {
  return axios.get('/api/events/past').then(response => response.data)
}


export function getEvent(eventId) {
  return axios.get(`/api/events/${eventId}`).then(response => response.data)
}

export function getFight(eventId, fightIndex) {
  return axios.get(`/api/events/${eventId}/fights/${fightIndex}`).then(response => response.data)
}

export function getAnalytics(eventId, fightIndex) {
  return axios.get(`/api/analytics/fight?eventId=${eventId}&fightIndex=${fightIndex}`).then(response => response.data)
}

export function getPrice(productId) {
  return axios.get(`/api/products/${productId}/price`).then(response => response.data)
}

export async function hasUserPurchased(productId) {
  return axios.get(`/api/user/purchase_check?productId=${productId}`).then(response => response.data)
}

export function getFighters(lastElement = null) {
  let ref = db.collection("fighters").orderBy('first_name')

  if (lastElement) {
    ref = ref.start_after(lastElement)
  }

  return ref.limit(10).get()
}

export function searchFighters(searchString, field, lastElement = null) {
  let ref = db.collection("fighters")
    // TODO array-contains does not work with string values
    // TODO need to find a different way to either search LIKE values
    // TODO or a completely different way to debug missing fighter data
    // .where(field, 'array-contains', searchString)
    .where(field, '>=', searchString)
    .where(field, '<=', searchString + '\uf8ff')

  if (lastElement) {
    ref = ref.start_after(lastElement)
  }

  return ref.orderBy('first_name').limit(10).get()
}