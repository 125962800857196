<template>
  <div>
    <div class="height-title">{{ title || component }}</div>
    <div class="attribute-row">
      <div v-if="!red || red <= 0" class="mr-16">No data</div>
      <div v-else class="attribute-row">
        <component
          :is="component"
          :isBig="isRedBig"
          :text="redString"
        ></component>
      </div>
      <div v-if="!blue || blue <= 0">No data</div>
      <div v-else class="attribute-row">
        <component
          :is="component"
          :isBig="isBlueBig"
          :text="blueString"
          class="blue-attribute"
        ></component>
      </div>
    </div>
  </div>
</template>
<script>
import Height from "./Height.vue";
import Reach from "./Reach.vue";

export default {
  name: "PhysiqueComparison",
  components: { Height, Reach },
  props: {
    title: String,
    component: String,
    red: Number,
    blue: Number,
  },
  computed: {
    redString() {
      return this.getInFeetInches(this.red);
    },
    blueString() {
      return this.getInFeetInches(this.blue);
    },
    isRedBig() {
      // TODO sometimes this is wrong because of rounding in display value
      // TODO values which get lost in rounding look the same but this
      // TODO function will show true value difference
      return this.red >= this.blue;
    },
    isBlueBig() {
      // TODO sometimes this is wrong because of rounding in display value
      // TODO values which get lost in rounding look the same but this
      // TODO function will show true value difference
      return this.blue >= this.red;
    },
  },
  methods: {
    getInFeetInches(reachInCM) {
      return (
        Math.trunc(reachInCM / 12) + "'" + Math.round(reachInCM % 12) + "''"
      );
    },
  },
};
</script>
<style>
.height-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.attribute-row {
  display: flex;
  justify-content: center;
}

.blue-attribute {
  padding-left: 24px;
}
</style>