<template>
  <v-card>
    <v-img
      class="white--text align-end"
      height="300px"
      :src="event.image_url"
      position="center 30%"
    >
      <!-- <v-card-title>{{title}}</v-card-title> -->
    </v-img>

    <v-card-title class="pb-0">
      {{ event.name }}
    </v-card-title>

    <v-card-text class="text--primary">
      <div>
        Get all the statistics used to make fight predictions including
        significant strikes and grappling takedowns.
      </div>
    </v-card-text>

    <v-progress-circular
      id="purchase-event__loader"
      v-if="loading"
      :size="70"
      color="black"
      indeterminate
    ></v-progress-circular>

    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="stripe"
        v-on:click="createCheckoutSession"
        id="purchase-btn"
        large
      >
        Purchase for only {{price}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";

import { createCheckoutSession } from "../../services/utils";

export default {
  name: "PurchaseEvent",
  props: {
    event: Object,
    eventId: String,
    fightId: String,
    price: String,
    alert: Function,
  },
  data: function () {
    return {
      loading: false,
      stripe: null,
    };
  },
  mounted: function () {
    this.loading = true;
    loadStripe(process.env.VUE_APP_STRIPE_API_KEY)
      .then((stripe) => {
        this.stripe = stripe;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    createCheckoutSession() {
      createCheckoutSession(this.eventId, this.fightId)
        .then((session) => {
          return this.stripe.redirectToCheckout({ sessionId: session.id });
        })
        .then((result) => {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            // TODO
            // alert(result.error.message);
            this.alert({});
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
};
</script>
<style>
#purchase-btn {
  color: white;
  padding: 16px;
  background: rgb(22, 85, 159);
}
</style>