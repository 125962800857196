/**
 * example object
 *
 * {
 *  '/events': {
 *    y: 234
 *  },
 *  '/events/643a1750b36a989ea3e92122': {
 *    y: 112
 *  }
 * }
 */
const scrollPosition = {}


export default scrollPosition;

/**
 * needs to be called like this:
 *
 * scrollToPosition.bind(this)();
 */
export function scrollToPosition() {
  this.$nextTick(() => {
    if (scrollPosition[this.$route.path]) {
      document.documentElement.scrollTop =
        scrollPosition[this.$route.path].y;
    }
  });
}