<template>
  <v-data-table
    id="fights-table"
    v-data-table
    :headers="headers"
    :items="data"
    class="elevation-1"
    @click:row="handleClick"
    :item-class="getRowClass"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:item.fight="{ item }">
      <div class="d-flex fight-cell">
        <div class="d-flex flex-column fighter-name red-fighter highlight">
          <div>{{ item.fight.redName }}</div>
        </div>
        <div class="mx-2">vs</div>
        <div class="d-flex flex-column fighter-name blue-fighter highlight">
          <div>{{ item.fight.blueName }}</div>
        </div>
      </div>
    </template>

    <template v-slot:item.prediction="{ item }">
      <div
        class="d-flex flex-column fighter-name highlight winner"
        :class="{
          'red-fighter': item.fight.redName === item.prediction,
          'blue-fighter': item.fight.blueName === item.prediction,
        }"
      >
        <div>{{ item.prediction }}</div>
      </div>
    </template>

    <template v-slot:item.winner="{ item }">
      <div
        class="d-flex flex-column fighter-name highlight winner"
        :class="{
          'red-fighter': item.fight.redName === item.winner,
          'blue-fighter': item.fight.blueName === item.winner,
        }"
      >
        <div>{{ item.winner }}</div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "FightsTable",
  props: {
    data: Array,
  },
  methods: {
    handleClick(row) {
      this.$router.push(
        `/events/${this.$route.params.eventId}/fights/${row.index}`
      );
    },
    getRowClass(item) {
      if (item.prediction && item.winner == item.prediction) {
        return "correct";
      } else if (
        !item.prediction ||
        (item.winner && item.winner != item.prediction)
      ) {
        return "incorrect";
      }
    },
  },
  data() {
    return {
      headers: [
        { text: "Fight", value: "fight", sortable: false },
        { text: "Judge 1", value: "judge1", sortable: false },
        { text: "Judge 2", value: "judge2", sortable: false },
        { text: "Judge 3", value: "judge3", sortable: false },
        { text: "Prediction", value: "prediction", sortable: false },
        // TODO numbers can be very misleading
        // TODO 2 judges vote for red, 1 judge vote for blue
        // TODO but when blue is very confident
        // TODO it makes it look like the system confidence is below 50%
        // { text: "Confidence", value: "confidence", sortable: false },
        { text: "Winner", value: "winner", sortable: false },
      ],
    };
  },
};
</script>
<style>
#fights-table .fighter-image {
  height: 100px;
  width: 100%;
}

.fight-cell {
  width: 100%;
}
#fights-table .fighter-name {
  flex: 1;
}

#fights-table .red-fighter.highlight {
  border-bottom: 3px solid rgb(210, 10, 10);
}

#fights-table .blue-fighter.highlight {
  border-bottom: 3px solid rgb(22, 85, 159);
}
#fights-table .winner {
  align-items: unset !important;
}

#fights-table tr:hover {
  cursor: pointer;
  background-color: #edf5ff;
}

#fights-table .correct {
  background-color: #4fd662;
}

#fights-table .incorrect {
  background-color: #efefef;
}
</style>