import { analytics } from "../firebase"

let currentUser

export function init(user) {
  currentUser = user
}

export function logEvent(eventName, options) {
  if (currentUser && currentUser.email !==  "mrtolgacura@gmail.com") {
    analytics.logEvent(eventName, options)
  }
}