var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{directives:[{name:"data-table",rawName:"v-data-table"}],staticClass:"elevation-1",attrs:{"id":"fights-table","headers":_vm.headers,"items":_vm.data,"item-class":_vm.getRowClass,"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.fight",fn:function({ item }){return [_c('div',{staticClass:"d-flex fight-cell"},[_c('div',{staticClass:"d-flex flex-column fighter-name red-fighter highlight"},[_c('div',[_vm._v(_vm._s(item.fight.redName))])]),_c('div',{staticClass:"mx-2"},[_vm._v("vs")]),_c('div',{staticClass:"d-flex flex-column fighter-name blue-fighter highlight"},[_c('div',[_vm._v(_vm._s(item.fight.blueName))])])])]}},{key:"item.prediction",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column fighter-name highlight winner",class:{
        'red-fighter': item.fight.redName === item.prediction,
        'blue-fighter': item.fight.blueName === item.prediction,
      }},[_c('div',[_vm._v(_vm._s(item.prediction))])])]}},{key:"item.winner",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column fighter-name highlight winner",class:{
        'red-fighter': item.fight.redName === item.winner,
        'blue-fighter': item.fight.blueName === item.winner,
      }},[_c('div',[_vm._v(_vm._s(item.winner))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }