<template>
  <v-card class="fighter-card">
    <div
      class="fighter-card__image"
      :style="{ backgroundImage: `url(${this.fighter.image_url})` }"
    ></div>
    <div>
      <div>First name: {{ this.fighter.first_name }}</div>
      <div>Last name: {{ this.fighter.last_name }}</div>
      <div v-if="this.fighter.nickname">Nickname: {{ this.fighter.nickname }}</div>
    </div>
    <div>
      <div>Wins: {{ this.fighter.wins }}</div>
      <div>Losses: {{ this.fighter.losses }}</div>
      <div>Draws: {{ this.fighter.draws }}</div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "FighterCard",
  props: {
    fighter: Object,
  },
};
</script>
<style>
.fighter-card {
  margin: 16px;
  padding: 16px;

  max-width: 300px;
}

.fighter-card__image {
  min-height: 200px;

  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
</style>